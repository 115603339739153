// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierAssignableUsers($assignId: ID!) {
    dossierAssignableUsers(assignId: $assignId) {
      id
      username
    }
  }
`;

export default QUERY;
